/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';

import { useAuth } from '../Auth/AuthProvider';
// import { getUser, isLoggedIn } from '../../services/auth';

import * as styles from './Menu.module.scss';
import 'bootstrap/dist/css/bootstrap.min.css'; // this works... but I don't want it here

export function Menu({ position, isAuthenticated, login }) {
  // const { isAuthenticated, login, logout } = useAuth();
  // const [isLoggedInState, setIsLoggedInState] = React.useState(false);
  // const [greetingMessage, setGreetingMessage] = React.useState('something');

  // React.useEffect(() => {
  //   (async () => {
  //     const loggedIn = await isLoggedIn();
  //     const { name } = await getUser();
  //     setIsLoggedInState(loggedIn);
  //     if (loggedIn) {
  //       setGreetingMessage(`Hello ${name}`);
  //     } else {
  //       setGreetingMessage('You are not logged in');
  //     }
  //   })();
  // }, []);

  React.useEffect(() => {
    console.log('isAuthenticated--> ', isAuthenticated, typeof login);
  }, [isAuthenticated]);

  return (
    <>
      {/* <div className={ position === 'bottom' ? null : styles.container }>
        <div className={ position === 'bottom' ? styles.footerContainer : styles.menuContainer }>
          <Row className={ position === 'bottom' ? styles.footerRow : styles.menuRow }>
            <Col xs={ 12 } lg={ 5 }><h1>Maxwell Family Reunion</h1></Col>
            { isLoggedInState ? (
              <>
                <Col xs={ 12 } lg={ 1 } className={ styles.centerCol }>{ greetingMessage }</Col>
                <Col xs={ 3 } md={ 2 } lg={ 1 }
                className={ styles.centerCol }><Link to='/app/reunion'>Reunion</Link></Col>
                <Col xs={ 3 } md={ 2 } lg={ 1 }
                className={ styles.centerCol }>Pictures</Col>
                <Col xs={ 3 } md={ 2 } lg={ 1 }
                className={ styles.centerCol }>
                <Link to='/app/family-links'>Family Links</Link></Col>
                <Col xs={ 3 } md={ 2 } lg={ 1 }
                className={ styles.centerCol }><Link to='/app/profile'>Profile</Link></Col>
                <Col xs={ 3 } md={ 2 } lg={ 1 }
                className={ styles.centerCol }><Link to='/app/reunion-guests'>Guests</Link></Col>
                <Col xs={ 12 } md={ 2 } lg={ 1 } className={ styles.centerCol }>
                  <Button
                    className={ styles.customButton }
                    href='http://localhost:3000/logout'
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   logout(() => navigate('/app/login'));
                  // }}
                  >
                    Logout
                  </Button>
                </Col>
                <br />
              </>
            ) : (
              <>
                <Col lg={ 2 } />
                <Col xs={ 4 } md={ 1 } lg={ 1 }
                className={ styles.centerCol }><Link to='/'>About</Link></Col>
                <Col xs={ 4 } md={ 2 } lg={ 1 }
                className={ styles.centerCol }><Link to='/app/reunion'>Reunion</Link></Col>
                <Col xs={ 4 } md={ 2 } lg={ 1 } className={ styles.centerCol }>
                  <Button
                    className={ styles.customButton }
                    href='/'
                    onClick={ (e) => {
                      e.preventDefault();
                      navigate('/app/login');
                    } }
                  >
                    Login
                  </Button>

                </Col>
              </>
            ) }
          </Row>
        </div>
        { position === 'bottom' && (
          <svg viewBox='0 0 500 50' preserveAspectRatio='xMinYMin meet'>
            <path d='M0,50 C150,50 350,0 500,50 L500,00 L0,0 Z'
            style={ { stroke: 'none', fill: 'rgba(79,93,52,1)' } } />
          </svg>
        ) }
      </div> */}
      { isAuthenticated /* isLoggedInState */ ? (
        <div>
          <Row className={ styles.titleRow }>
            <Col xs={ 3 }>
              <div alt='maxwell tartan' className={ styles.tartanBox } />
            </Col>
            <Col xs={ 9 } className={ styles.title }>
              <h1>Maxwell Family Reunion</h1>
            </Col>
          </Row>
          <Row className={ styles.linkRow }>
            <Col />
            <Col />
            <Col><Link to='/family/reunion'>Reunion</Link></Col>
            <Col><Link to='/family/files'>Files</Link></Col>
            <Col><Link to='/family/links'>Family Links</Link></Col>
            {/* <Col><Link to='/family/profile'>Profile</Link></Col> */ }
            <Col>
              <Button
                className={ styles.customButton }
                href={ `${process.env.SERVER_URL}/logout` }
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row className={ styles.titleRow }>
            <Col xs={ 3 }>
              <div alt='maxwell tartan' className={ styles.tartanBox } />
            </Col>
            <Col xs={ 9 } className={ styles.title }>
              <h1>Maxwell Family Reunion</h1>
            </Col>
          </Row>
          <Row className={ styles.linkRow }>
            <Col />
            <Col />
            <Col />
            <Col><Link to='/'>Home Page</Link></Col>
            <Col><Link to='/family/reunion'>Reunion</Link></Col>
            <Col>
              <Button
                className={ styles.customButton }
                href='/'
                onClick={ (e) => {
                  e.preventDefault();
                  // login();
                  navigate('/family/login');
                } }
              >
                Login
              </Button>
            </Col>
          </Row>
        </div>
      ) }
      {/* <div className={styles.whiteSpace} /> */ }
    </>
  );
}
