import React from 'react';
import { Container } from 'react-bootstrap';

import { Menu } from '../Menu/Menu';
// import { Footer } from '../Footer/Footer';
import * as styles from './Layout.module.scss';

export function Layout({ children, isAuthenticated, login }) {
  return (
    <div>
      <Container>
        <Menu isAuthenticated={ isAuthenticated } login={ login } />
        <div className={ styles.mainArea }>{ children }</div>
        {/* <Menu position="bottom" /> */ }
      </Container>
    </div>
  );
}
